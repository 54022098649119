<template>
    <div class="full-height-layout fill">
        <router-view ref="routeview" class="full-height-layout fill"></router-view>
    </div>
</template>

<script>
export default {
    name: 'ArtworkManagementView',
}
</script>
